import {
    onCreateDailyLogByGroup,
    onUpdateDailyLogByGroup,
    onDeleteDailyLogByGroup
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription,
    updateElement,
    updateRenderKey
} from '../helper'
import EventBus from '@/eventBus'
import moment from 'moment-timezone';

"use strict";
let subscriptionDailyLog = null

export const dailyLogSubscribe = function(instance){
    const queries = {
        onCreateDailyLogByGroup,
        onUpdateDailyLogByGroup,
        onDeleteDailyLogByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionDailyLog, queries, input, "subscriptionStore/handleDailyLog", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionDailyLog = subscriptions['dailyLog'] = {
        loadList: null,
        subscribe: dailyLogSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: dailyLogEventCallback,
        subscription
    }
}


export const dailyLogEventCallback = function(subscriptionState, {data: dailyLog, eventName}){
    if(!dailyLog?.id) return
    
    EventBus.$emit('update-route-render', {
        target: "table-data-dailyLog",
        dailyLog
    })
}