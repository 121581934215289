import {
    onCreateAccidentByGroup,
    onDeleteAccidentByGroup
} from '@/store/subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription
} from '../helper'
import EventBus from '@/eventBus'

"use strict";
let subscriptionAccident = null

export const accidentSubscribe = function(instance){
    const queries = {
        onCreateAccidentByGroup,
        onDeleteAccidentByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionAccident, queries, input, "subscriptionStore/handleAccident", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionAccident = subscriptions['accident'] = {
        loadList: null,
        subscribe: accidentSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: accidentEventCallback,
        subscription
    }
}

export const accidentEventCallback = async function(subscriptionState, { data: accident, eventName }){
    if(!accident) return

    EventBus.$emit('update-route-render', {
        target: "table-data-odmeterReading",
        accident
    })
    
}