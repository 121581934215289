import {
    onCreateReplaceByRouteByGroup,
    onUpdateReplaceByRouteByGroup,
    onDeleteReplaceByRouteByGroup
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription,
    updateElement,
    updateRenderKey
} from '../helper'
import EventBus from '@/eventBus'

"use strict";
let subscriptionReplaceByRoute = null

export const replaceByRouteSubscribe = function(instance){
    const queries = {
        onCreateReplaceByRouteByGroup,
        onUpdateReplaceByRouteByGroup,
        onDeleteReplaceByRouteByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionReplaceByRoute, queries, input, "subscriptionStore/handleReplaceByRoute", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionReplaceByRoute = subscriptions['replaceByRoute'] = {
        loadList: null,
        subscribe: replaceByRouteSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: replaceByRouteEventCallback,
        subscription
    }
}

export const replaceByRouteEventCallback = function(subscriptionState, {data: replaceByRoute, eventName}){
    if(!replaceByRoute?.id) return

    EventBus.$emit('update-route-render', {
        target: "table-data-routes",
        replaceByRoute,
        from: 'replaced-by-route',
        routeId: replaceByRoute.route?.id,
        eventName
    })
}