import {
    onUpdateDailyRosterByGroup
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription,
    updateElement
} from '../helper'
import EventBus from '@/eventBus'

"use strict";
let subscriptionDailyRoster = null

const reloadDailyRoster = function(subscriptionDailyRoster){
    const { dailyRosterCache, dailyLogCache } = store.state.subscriptionStore
    if(dailyRosterCache.length && dailyRosterCache.lastRenderKey){
        dailyRosterCache.length = 0
        for (const key in dailyLogCache){
            delete dailyLogCache[key]
        }
    }
    dailyRosterCache.lastRenderKey = +(new Date)
}

export const dailyRosterSubscribe = function(instance){
    const queries = {
        onUpdateDailyRosterByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionDailyRoster, queries, input, "subscriptionStore/handleDailyRoster", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionDailyRoster = subscriptions['dailyRoster'] = {
        loadList: null,
        subscribe: dailyRosterSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: dailyRosterEventCallback,
        subscription
    }
    subscriptionDailyRoster.loadList = () => reloadDailyRoster(subscriptionDailyRoster)
}

export const dailyRosterEventCallback = function(subscriptionState, {data: dailyRoster, eventName}){
    if(!dailyRoster?.id) return
    const match = subscriptionState.dailyRosterCache.find(dr => dr.id === dailyRoster.id)
    if(!match) return
    switch(eventName){
        case 'onUpdate':{
            updateElement(match, dailyRoster, true)
            break
        }
    }
}