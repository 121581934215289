/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://husgmtwv32.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "ForceLogoutAndRefresh",
            "endpoint": "https://edjqfzp71h.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "ManageNotificationPreferences",
            "endpoint": "https://9kc8jpxv92.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "ProcessPerformanceData",
            "endpoint": "https://gljoj53zad.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "ScheduleNotifications",
            "endpoint": "https://runbvtl4bk.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "SendInternalMessage",
            "endpoint": "https://43dci14x6a.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "Signup",
            "endpoint": "https://un3bhwx6wh.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "bandwidth",
            "endpoint": "https://2gr0gfj3oj.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "counseling",
            "endpoint": "https://s6f4kz4t33.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "csvDataExport",
            "endpoint": "https://80353ht5q8.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "documentSignService",
            "endpoint": "https://jqtqi0mp20.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "downloadFiles",
            "endpoint": "https://bh90i7agdb.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "generateCounselingFilesApi",
            "endpoint": "https://dtmh20kof7.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "handleTelnyxResponse",
            "endpoint": "https://p8gff6xsog.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "heraPublicApi",
            "endpoint": "https://s2j6xkv69j.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "intercomUpdateCompanies",
            "endpoint": "https://c11d89a268.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "invoice",
            "endpoint": "https://qiv02gv280.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "messages",
            "endpoint": "https://6vknpb5arc.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "rds",
            "endpoint": "https://c25fb7mtf2.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "s3downloadlink",
            "endpoint": "https://fu27j7uv6h.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "sendMessage",
            "endpoint": "https://70gd29cw09.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "sendMessages",
            "endpoint": "https://fq5uz4r0ge.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "sendPendingCoachingMessage",
            "endpoint": "https://2i491kbik5.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "sesSuppression",
            "endpoint": "https://fk31vlqvz7.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "stripeCharge",
            "endpoint": "https://2cca31p1ve.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "stripeSetup",
            "endpoint": "https://eupb3zjrgg.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "tenant",
            "endpoint": "https://m4htg1mnbf.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "trackAuditLog",
            "endpoint": "https://z820acjdy6.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        },
        {
            "name": "zoho",
            "endpoint": "https://2rx8kkme52.execute-api.us-east-2.amazonaws.com/dqa",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://r3oql2mpq5bgdhwjhs7oy5qc7y.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-qaoksxcrpzai5pwkr26cdhymya",
    "aws_cognito_identity_pool_id": "us-east-2:0ba99f91-5956-478a-96b0-380d1b681ee6",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_fqfQpZ7z8",
    "aws_user_pools_web_client_id": "7s1s7cod99qmt5324e0tgccrl",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hera2009fb1ba5504c8da9ba5f1fe34db738ddc04-dqa",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
