import heicConvert from 'heic-convert/browser';

/**
 * 
 * @param {*} imageDownloaded 
 * @returns blob
 */
export async function previewDownloadedHeicImage(imageDownloaded) {
    let blob = null
    try {
        const arrayBuffer = await imageDownloaded.Body.arrayBuffer(); 
        var inputBuffer = Buffer.from(arrayBuffer); 
        const outputBuffer = await heicConvert({ 
            buffer: inputBuffer, // Buffer containing HEIC file 
            format: 'JPEG', // Output format 
            quality: 1 // Quality scale: 0 (lowest) to 1 (highest) 
        });
                    
        blob = new Blob([outputBuffer], { type: 'image/jpeg' }); 
    } catch (error) {
        blob = new Blob([inputBuffer]);
        console.error("Error converting HEIC image:", error);
    }
    return blob
}



/**
 * 
 * @param {*} file 
 * @returns blob
 */
export async function previewHeicImage(file){
    let blob = null
    try {
        const arrayBuffer = await file.arrayBuffer()
        var inputBuffer = Buffer.from(arrayBuffer); 
        const outputBuffer = await heicConvert({ 
            buffer: inputBuffer, // Buffer containing HEIC file 
            format: 'JPEG', // Output format 
            quality: 1 // Quality scale: 0 (lowest) to 1 (highest) 
        });
        blob = new Blob([outputBuffer], { type: 'image/jpeg' });
      
    } catch (error) {
        blob = new Blob([inputBuffer]);
        console.error("Error converting HEIC image:", error);
    }
    return blob;
}

/**
 * 
 * @param {*} file 
 * @returns converted file
 */
export async function convertHeicToJPEG(file) {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const outputBuffer = await heicConvert({
        buffer: new Uint8Array(arrayBuffer),
        format: 'JPEG', // Output format 
        quality: 0.8, // Quality scale: 0 (lowest) to 1 (highest) 
      });
  
      const newFileName = file.name.replace(/\.\w+$/, '.jpeg');
      return new File([outputBuffer], newFileName, { type: 'image/jpeg' });
    } catch (error) {
      console.error("Error en conversión HEIC:", error);
      return file;
    }
}

/**
 * 
 * @param {*} file 
 * @returns true or false
 */
export function isHEICFile(file) {
    return file.type === "image/heic" ||  /\.heic$/i.test(file.name);
}
