// Non-editable "Option custom list" for hera-AI prompt tests
export const OPTION_CUSTOM_LIST_NOT_EDITABLE = [
  "Odometer",
  "Interior Front Driver Seat & Floor",
  "Interior Front Passenger Seat & Floor",
  "Interior Cargo Bay",
  "Front of Vehicle",
  "Back of Vehicle",
  "License Plate",
  "Driver Side of Vehicle",
  "Passenger Side of Vehicle",
  "Engine",
  "Dipstick",
  "Tire Front Driver Side",
  "Tire Front Passenger Side",
  "Tire Back Driver Side",
  "Tire Back Passenger Side"
]