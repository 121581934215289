export const getValueList = /* GraphQL */ `
  query GetValueList($id: ID!) {
    getValueList(id: $id) {
      id
      group
      key
      items {
        items {
          id
          value
          custom
          driverReportSetting
          deleted
          hidden
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const listDailyRostersPM = /* GraphQL */ `
query ListDailyRosters(
  $filter: ModelDailyRosterFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyRosters(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      notesDate
    }
    nextToken
  }
}
`;

export const listDailyRostersRoutes = /* GraphQL */ `
query ListDailyRosters(
  $filter: ModelDailyRosterFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyRosters(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      notesDate
      route {
        items {
          id
          group
          notes
          routeNumber
          status
          parkingSpace
          staging
          standby
          time
          messageSentTime
          messageSentError
          staff {
            id
            firstName
            lastName
            receiveTextMessages
            receiveEmailMessages
            phone
            email
          }
          helper {
            id
            firstName
            lastName
            receiveTextMessages
            receiveEmailMessages
            phone
            email
          }
          messages {
            items{
              id
              bodyText
              createdAt
              emailStatus
              smsStatus
              smsSendInformation
              emailSendInformation
              staff {
                id
                firstName
              }
            }
          }
          vehicle {
            id
            name
            parkingSpace
            device {
              id
              deviceName
            }
            vehicleType {
              id
              group
              order
              option
            }
          }
          document{
            items{
              id
              name
              key
              type
              route{
                id
              }
            }
          }
          device {
            id
            deviceName
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;



export const listDeviceNamesAndIds = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        deviceName
      }
      nextToken
    }
  }
`;

export const listDevicesWithVehicleId = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        deviceName
        phoneNumber
        carrier
        vehicle: vehicle {
          items {
            id
          }
        }
        route: route {
          items {
            id
          }
        }
        status
        notes
      }
      nextToken
    }
  }
`;

export const listTextractJobs = /* GraphQL */ `
  query TextractJobsByOwner(
    $owner: String
    $jobStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByOwner(
      owner: $owner
      jobStatus: $jobStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        key
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        results
      }
      nextToken
    }
  }
`;

export const textractJobsByOwnerAndIsProcessedS = /* GraphQL */ `
  query TextractJobsByOwnerAndIsProcessedS(
    $owner: String
    $isProcessedS: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByOwnerAndIsProcessedS(
      owner: $owner
      isProcessedS: $isProcessedS
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        key
        week
        year
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
      }
      nextToken
    }
  }
`;

export const staffIndex = /* GraphQL */ `
query StaffsByGroup(
  $group: String
  $firstName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroup(
    group: $group
    firstName: $firstName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      transporterId
      firstName
      lastName
      phone
      coachingOpportunity
      latestScorecard
      status
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      group
      firstName
      lastName
      phone
      email
      coachingOpportunity
      status
      onBoarding {
        items {
          id
          name
          isComplete
          status
          dateComplete
        }
        nextToken
      }
      uniforms {
        items {
          id
          qty
          issueDate
          uniformType {
            id
            value
            deleted
          }
          size {
            id
            value
            deleted
          }
        }
        nextToken
      }
    }
  }
`;

export const getOptionsCustomListsStaff = /* GraphQL */ `
  query GetOptionsCustomListsStaff($id: ID!) {
    getOptionsCustomListsStaff(id: $id) {
      id
      group
      optionCustomList {
        id
        group
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          createdAt
          updatedAt
        }
        order
        option
      }
      createdAt
      updatedAt
      staff {
        id
        group
        firstName
        lastName
        alternateNames
        phone
        email
      }
    }
  }
`;

export const listStaffsDailyRoster = /* GraphQL */ `
  query StaffsByGroup(
    $group: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroup(
      group: $group
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        firstName
        lastName
        alternateNames
        status
        phone
        email
        receiveTextMessages
        receiveEmailMessages
        defaultVehicle{
          id
          name
          status
          device {
            id
            deviceName
            status
          }
          parkingSpace {
            id
            group
            order
            option
          }
          vehicleType {
            id
            group
            option
          }
        }
        defaultVehicle2{
          id
          name
          status
          device {
            id
            deviceName
            status
          }
          parkingSpace {
            id
            group
            order
            option
          }
          vehicleType {
            id
            group
            option
          }
        }
        defaultVehicle3{
          id
          name
          status
          device {
            id
            deviceName
            status
          }
          parkingSpace {
            id
            group
            order
            option
          }
          vehicleType {
            id
            group
            option
          }
        }
        authorizedToDrive {
          items {
            id
            optionCustomList {
              id
              option
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;



export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationByEmail
      receiveNotificationBySms
      role
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionManageLabels
      permissionCompanySettingsRosterChecklistTemplates
      permissionMessageTemplate
      permissionTasksReports
      permissionVehicleManagement
      permissionVehiclePhotoLogs
      lastLogin
      createdAt
      tenant {
        id
        allowLibraryUpload
        companyName
        customerStatus
        automatedCoachingSendTime
        coachingDriverRankRange
        logo
        accountPremiumStatus
        trialExpDate
        customerSubType
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        payOutstandingByDate
        originationNumber
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        stripeCustomerId
        stripeBillingEmail
        timeZone
        shortCode
        usesXLcoaching
        zohoCrmAccountRecordId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        createdAt
        updatedAt
      }
    }
  }
`;

export const getStaffTransporterIds = /* GraphQL */ `
  query StaffsByGroup(
    $group: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroup(
      group: $group
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        transporterId
        latestScorecard
        keyFocusArea
        firstName
        lastName
        alternateNames
        status
      }
      nextToken
    }
  }
`;

export const vehicleIndex = /* GraphQL */ `
  query ListVehicles(
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        mileage
        gasCard
        vin
        licensePlate
        company {
          id
          option
        }
        status
      }
      nextToken
    }
  }
`;

export const staffScorecardsByGroup = /* GraphQL */ `
  query StaffScoreCardsByGroup(
    $group: String
    $yearWeek: ModelStaffScoreCardByGroupCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffScoreCardsByGroup(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        matched
        week
        year
        name
        transporterId
        overallTier
        delivered
        keyFocusArea
        ficoScore
        seatbeltOffRate
        dcr
        dar
        swcPod
        swcCc
        swcSc
        swcAd
        dnrs
        podOpps
        ccOpps      
      }
      nextToken
    }
  }
`;

export const cxFeebackByGroup = /* GraphQL */ `
  query StaffCxFeedbacksByGroup(
    $group: String
    $yearWeek: ModelStaffCxFeedbackByGroupCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffCxFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffCxFeedbacksByGroup(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        week
        year
        name
        transporterId
        positiveFeedback
        negativeFeedback
        deliveryWasGreat
        deliveryWasntGreat
        totalDeliveries
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        careForOthers
        mishandledPackage
        drivingUnsafely
        driverUnprofessional
        notDeliveredToPreferredLocation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const messagesByGroupChannelTypeIsReadS = /* GraphQL */ `
  query MessagesByGroupChannelTypeIsReadS(
    $group: String
    $channelTypeIsReadSCreatedAt: ModelMessageByGroupChannelTypeIsReadSCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroupChannelTypeIsReadS(
      group: $group
      channelTypeIsReadSCreatedAt: $channelTypeIsReadSCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt 
        channelType
        staffId
        staff{
          status
        }
    }
    nextToken
  }
}
`;

export const messagesByGroupChannelType = /* GraphQL */ `
  query MessagesByGroupChannelType(
    $group: String
    $channelTypeCreatedAt: ModelMessageByGroupChannelTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroupChannelType(
      group: $group
      channelTypeCreatedAt: $channelTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt 
        channelType
        staffId
        staff{
          status
        }
        isReadBy{
          items {
            userID
          }
        }
      }
    nextToken
  }
}
`;



export const getTenantCoachingMessageSettings = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      group
      companyName
      coachingCustomMessage
      coachingFicoCO
      coachingFicoPR
      coachingFicoThreshold
      coachingFicoThresholdIssue
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoKudo
      coachingDcrCO
      coachingDcrPR
      coachingDcrThreshold
      coachingDcrThresholdIssue
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrKudo
      coachingDarCO
      coachingDarPR
      coachingDarThreshold
      coachingDarThresholdIssue
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarKudo
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodThreshold
      coachingSwcPodThresholdIssue
      coachingSwcPodThresholdKudo
      coachingSwcPodIssue
      coachingSwcPodKudo
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcThreshold
      coachingSwcCcThresholdIssue
      coachingSwcCcThresholdKudo
      coachingSwcCcIssue
      coachingSwcCcKudo
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScThreshold
      coachingSwcScThresholdIssue
      coachingSwcScThresholdKudo
      coachingSwcScIssue
      coachingSwcScKudo
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdThreshold
      coachingSwcAdThresholdIssue
      coachingSwcAdThresholdKudo
      coachingSwcAdIssue
      coachingSwcAdKudo
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffKudo
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventKudo
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackKudo
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoKudo
      coachingDvcrsCO
      coachingDvcrsPR
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingDnrCO
      coachingDnrPR
      coachingDnrThreshold
      coachingDnrThresholdIssue
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrKudo
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateKudo
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateKudo
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateKudo
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
    }
  }
`;

export const getPodQualityCoachingMessages = /* GraphQL */ `
  query GetPodQuality($id: ID!) {
    getPodQuality(id: $id) {
      id
      blurry
      personInPhoto
      noPackage
      takenFromCar
      packageInHand
      notClearlyVisible
      packageTooClose
      photoTooDark
      other
      grandTotal
    }
  }
`;

export const getStaffCxFeedbackCoachingMessages = /* GraphQL */ `
  query GetStaffCxFeedback($id: ID!) {
    getStaffCxFeedback(id: $id) {
      positiveFeedback
      negativeFeedback
      mishandledPackage
      driverUnprofessional
    }
  }
`;

export const getPendingMessage = /* GraphQL */ `
  query GetPendingMessage($id: ID!) {
    getPendingMessage(id: $id) {
      id
      group
      createdAt
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      bodyTextDailyCO
      bodyTextMentorCO
      bodyTextNetradyneCO
      bodyTextDailyPR
      bodyTextMentorPR
      bodyTextNetradynePR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      bodyTextCO
      bodyTextPR
      subject
      bodyText
      bodyHtml
      smsSendInformation
      emailSendInformation
      importId
    }
  }
`;

export const staffScorecardsGroupUnmatched = /* GraphQL */ `
  query StaffScorecardsGroupUnmatched(
    $group: String
    $matchedSYearWeek: ModelStaffScoreCardByGroupUnmatchedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffScorecardsGroupUnmatched(
      group: $group
      matchedSYearWeek: $matchedSYearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        name
        transporterId
        overallTier
        delivered
        keyFocusArea
        ficoScore
        seatbeltOffRate
        dcr
        dar
        swcPod
        swcCc
        swcSc
        swcAd
        dnrs
        podOpps
        ccOpps
      }
      nextToken
    }
  }
`;

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      group
      createdAt 
      channelType
      staffId
      bodyText
      isReadS
      messageType
    }
  }
`;

export const deviceIds = /* GraphQL */ `
  query DevicesByGroup(
    $group: String
    $deviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByGroup(
      group: $group
      deviceName: $deviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const vehicleIds = /* GraphQL */ `
  query VehiclesByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const staffIds = /* GraphQL */ `
  query StaffsByGroup(
    $group: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroup(
      group: $group
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id 
      }
      nextToken
    }
  }
`;

export const customListsByGroup = /* GraphQL */ `
  query CustomListsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customListsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        listCategory
        listName
        listDisplay
        enabledRecordTemplates
        canUseHeraAiPrompts
        options {
          items {
            id
            group
            order
            option
            key
            default
            isEnabled
            pillColor
            statusType
            isCustom
            usedFor
            daysCount
            canBeEdited
            canBeDeleted
            canBeReorder
            statusType
            driverReportSetting
            isDefaultForSections
            isHiddenForSections
            recordTemplateJson
            updatedAt
            heraAiPrompt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const optionsCustomListsByGroup = /* GraphQL */ `
  query OptionsCustomListsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptionsCustomListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    optionsCustomListsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          createdAt
          updatedAt
        }
        order
        option
        default
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        accidents {
          items {
            id
            group
          }
        }
        associates {
          items {
            id
            group
          }
        }
        createdAt
        updatedAt
        vehicles {
          items {
            id
            group
          }
        }
      }
      nextToken
    }
  }
`;

export const staffsCounterByGroupStatus = /* GraphQL */ `
  query StaffsByGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      scannedCount
      nextToken
    }
  }
`;

//query for dropdowns
export const staffsByGroupStatus = /* GraphQL */ `
  query StaffsByGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        status
      }
      nextToken
    }
  }
`;

