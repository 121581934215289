import {
    onCreateDocumentByGroup,
    onUpdateDocumentByGroup,
    onDeleteDocumentByGroup
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription
} from '../helper';
import EventBus from '@/eventBus';

"use strict";
let subscriptionDocument = null;

export const documentSubscribe = function (instance) {
    const queries = {
        onCreateDocumentByGroup,
        onUpdateDocumentByGroup,
        onDeleteDocumentByGroup
    };

    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }

    initializeSubscription(subscriptionDocument, queries, input, "subscriptionStore/handleDocument", instance);
};

export const config = function (subscriptions) {
    const subscription = {};
    subscriptionDocument = subscriptions['document'] = {
        loadList: null,
        subscribe: documentSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: documentEventCallback,
        subscription
    };
};

export const documentEventCallback = function (subscriptionState, { data: document, eventName }) {
    if (!document?.id || !document?.documentDailyLogId) return;
    
    EventBus.$emit('document-event', {
      eventName,
      document
    });
  };